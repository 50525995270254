var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.tangibleReportsColumns,"items":_vm.items,"server-items-length":_vm.serverItemsLength,"loading":_vm.loading,"options":_vm.tempOptions},on:{"update:options":function($event){_vm.tempOptions=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.report.status)+" ")]),(item.report.status === 'in_progress')?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()]}},{key:"item.report_from_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateSimple")(item.report.report_from_date))+" ")])]}},{key:"item.report_to_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateSimple")(item.report.report_to_date))+" ")])]}},{key:"item.merchant",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.merchant ? item.merchant.code : 'ALL'))])]}},{key:"item.sent_at",fn:function(ref){
var item = ref.item;
return [(item.sent_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateSimple")(item.sent_at))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.downloadReport(item)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Download Report (xlsx)")])])],1),_c('v-list-item',{on:{"click":function($event){return _vm.resendReport(item)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Resend to..")])])],1)],1)],1)]}}],null,true)})],1),(_vm.sendReport)?_c('resend-tangible-report-dialog',{attrs:{"show":true,"report-id":_vm.sendReport.id,"default-email":_vm.defaultEmail},on:{"close":function($event){_vm.sendReport = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="merchant-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>

        <v-card class="mt-5">
          <v-card-text>
            <div class="d-flex align-center">
              <v-text-field
                v-model="form.from"
                type="date"
                label="Day Start"
                class="mr-2"
              />

              <v-text-field
                v-model="form.to"
                type="date"
                label="Day End"
                class="mr-2"
              />

              <act-as-merchant-options class="ml-2" />

              <v-btn
                color="primary"
                :loading="loading"
                class="mr-2"
                @click="reloadAll"
              >
                Show
              </v-btn>

              <v-btn
                v-if="currentTab === 0"
                color="success"
                :loading="loading"
                @click="generateTangibleReport"
              >
                Generate +
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-tabs
          v-model="currentTab"
          show-arrows
          class="user-tabs mt-2"
        >
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :disabled="tab.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="currentTab"
          class="mt-5 pa-1"
        >
          <!-- <v-tab-item>
            <merchant-tangible-summary
              :loading="loading"
              :items="tangibleSummary"
            />
          </v-tab-item> -->
          <v-tab-item>
            <merchant-tangible-reports
              :loading="loading"
              :items="tangibleReports"
              :server-items-length="tangibleReportsTotal"
              :options.sync="options"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ActAsMerchantOptions } from '@/components'
import router from '@/router'
import { mdiAlphaRBox } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import MerchantTangibleReports from './overview/MerchantTangibleReports.vue'
import MerchantTangibleSummary from './overview/MerchantTangibleSummary.vue'
import useTangibleReport from './useTangibleReports'

export default {
  components: {
    MerchantTangibleSummary,
    MerchantTangibleReports,
    ActAsMerchantOptions,
  },

  setup() {
    // UnRegister on leave
    const {
      options, form, loading, reloadAll, generateTangibleReport, tangibleSummary, tangibleReports, tangibleReportsTotal,
    } = useTangibleReport()

    const tabs = computed(() => [
      // { icon: mdiAlphaTBox, title: 'TANGIBLE SUMMARY' },
      { icon: mdiAlphaRBox, title: 'TANGIBLE REPORTS' },
    ])

    const currentTab = ref(0)

    const switchToDefaultTab = () => {
      const tab = router.currentRoute.query.tab || null
      const index = tabs.value.findIndex(t => t.title === tab)
      currentTab.value = Math.max(0, index)
    }

    onMounted(() => {
      // reloadMerchant().then(switchToDefaultTab)
      switchToDefaultTab()
    })

    return {
      tabs,

      options,
      form,
      loading,
      reloadAll,
      generateTangibleReport,
      tangibleSummary,
      tangibleReports,
      tangibleReportsTotal,

      currentTab,
    }
  },
}
</script>